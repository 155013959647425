import request from "@/utils/request";

// 报文日志
export function getDatagramLog(params) {
  return request({
    url: "/log/datagram",
    method: "Get",
    params: params,
  });
}

// 反控日志
export function getCounterchargeLog(params) {
  return request({
    url: "/log/countercharge",
    method: "Get",
    params: params,
  });
}

// 断线日志
export function getBreakLog(params) {
  return request({
    url: "/log/break",
    method: "Get",
    params: params,
  });
}

// 短信日志
export function getMessageLog(params) {
  return request({
    url: "/log/message",
    method: "Get",
    params: params,
  });
}

// 断线统计
export function getBreakStatisticLog(params) {
  return request({
    url: "/log/break-statistic",
    method: "Get",
    params: params,
  });
}

// 操作日志
export function getWebLog(params) {
  return request({
    url: "/log/api",
    method: "Get",
    params: params,
  });
}
